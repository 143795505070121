

const apiconfig = require("../config/negosyonow-config");
const requestJSON = require("./requestJSON");

const getURL = (entity, action) => {
	return apiconfig.url.protocol+"://"+apiconfig.url.host+"/"+action+"/"+entity;
}


const buildOrder = (jwtToken, type, params) => {
	return requestJSON.send( getURL(type.toLowerCase(), "databuild") , "POST", params, jwtToken);
}

const buildEntityId = (pkid, jwtToken, entity) => {
	return requestJSON.send( getURL(entity.toLowerCase()+"id", "databuild") , "POST",{"id": pkid}, jwtToken);
}

const buildEntityIdList = (idlist, jwtToken, entity) => {
	return requestJSON.send( getURL(entity.toLowerCase()+"idlist", "databuild") , "POST",{"idlist": idlist}, jwtToken);
}


const buildEntitybyParam = (param, jwtToken, entity) => {
	return requestJSON.send( getURL(entity.toLowerCase()+"id", "databuild") , "POST",param, jwtToken);
}


const buildEntityIdListbyParam = (param, jwtToken, entity) => {
	return requestJSON.send( getURL(entity.toLowerCase()+"idlist", "databuild") , "POST",param, jwtToken);
}

const loadFormData = (url, jwtToken) => {
	// Retrieves submission payload (e.g. seminar registration)
	return requestJSON.send( getURL("json", "loadform") , "POST",{"url": url}, jwtToken);
}

const activateEntity = (pkid, jwtToken, entity) => {
	// Should trigger corresponding build
	return requestJSON.send( getURL(entity, "activate") , "POST",{"documentid": pkid}, jwtToken);
}


module.exports = {
	buildOrder,
	buildEntityId,
	buildEntityIdList,
	buildEntitybyParam,
	buildEntityIdListbyParam,
	loadFormData,
	activateEntity
}
